import { FormCompleteWorkOrderSettingsShort } from './form-complete-work-order-settings-short';

export class FormCompleteWorkOrderSettings {
  type = 'short' as const;
  short = new FormCompleteWorkOrderSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}
