import { Injectable } from '@angular/core';
import type { Enterprise, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  enterprises: { items: Enterprise[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class EnterprisesGQL extends Query<Response, GraphQLParams<'enterprise'>> {
  document = gql(config.enterprise.query);
}
