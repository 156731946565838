/* eslint-disable @typescript-eslint/naming-convention */
// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.

import { FormPurchaseOrderLinesSettings } from './form-purchase-order-lines-settings';
import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// To keep consistency with the other models. This one has the short / long type as well but is not used.
export class FormPurchaseOrderSettings {
  type = 'short' as const;
  short = new FormPurchaseOrderSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}

export class FormPurchaseOrderSettingsShort {
  payment = {
    bought_From_Vendor: true,
    enabled: true,
    location: true,
    payment_Method: false,
    total_Payable: true,
    work_Order: false,
    comment: true,
  };
  purchase_Order_Lines: FormPurchaseOrderLinesSettings = {
    enabled: true,
    purchasedPrice: true,
    type: true,
    unit_of_Measure_Code: true,
    work_Order: true,
    work_Order_Line: true,
    job_No: false,
    job_Description: false,
    job_Task_No: false,
    job_Task_Description: false,
    job_Line_Type: false,
  };
  attachment = {
    enabled: false,
  };
  requirements = new FormRequirementsModalSettings();
}
