/* eslint-disable @typescript-eslint/naming-convention */

import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
export class FormInspectionSettingsShort {
  basic_information = {
    additional_Description: true,
    assign: true,
    duration: true,
    enabled: true,
    equipment: true,
    needed_By: true,
    problem_Code: true,
    problem_description: true,
    document_Status: true,
    status: false,
    technician_Code: false,
    type: true,
  };
  suggested_Tasks = {
    description: true,
    duration: false,
    enabled: true,
    equipment_ID: false,
    line: true,
    technician_Code: true,
    usage: true,
    work_Procedure: true,
  };
  requirements = new FormRequirementsModalSettings();
  additional_Information = {
    enabled: false,
  };
  attachment = {
    enabled: false,
  };
}
