/* eslint-disable @typescript-eslint/naming-convention */
// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
// To keep consistency with the other models. This one has the short / long type as well but is not used.
export class FormEquipmentSettings {
  type = 'short' as const;
  short = new FormEquipmentSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}

export class FormEquipmentSettingsShort {
  basic_information = {
    criticality: true,
    enabled: true,
    enterprise: false,
    equip_Serial_No: true,
    equipment_Group: true,
    equipment_Subgroup: true,
    line: true,
    main: true,
    manufacturer: true,
    master_ID: true,
    model_Number: true,
    primary: true,
    region: false,
    secondary: true,
    asset_Identifier: true,
    area: true
  };
  barcode = {
    enabled: true,
  };
  additional_Information = {
    enabled: false,
  };
  attachment = {
    enabled: false,
  };
}
