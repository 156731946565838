import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Select, Store } from '@ngxs/store';
import {
  Area,
  Attachment,
  BinContent,
  Criticality,
  Employee,
  Enterprise,
  Equipment,
  EquipmentFailureCode,
  EquipmentGroup,
  EquipmentSubGroup,
  Facility,
  GLAccount,
  Item,
  Job,
  JobTaskLine,
  Line,
  Location,
  MainCode,
  MaintenanceType,
  OrderType,
  Personnel,
  PersonnelGroup,
  PrimaryCode,
  ProblemCode,
  PriorityRank,
  Region,
  RelatedRequirement,
  Request,
  SecondaryCode,
  StatusCode,
  TAGSetup,
  DocumentTracking,
  Vendor,
  WorkOrderTemplate,
  WorkCode,
  WorkOrder,
  WorkProcedure,
  WorkOrderLine,
} from '@tag/graphql';
import { addMinutes, startOfDay } from 'date-fns';
import { Operation, compare } from 'fast-json-patch';
import { merge } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, of, take } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CardLocation } from '@cards-models/card-location';
import { FormPersonnelService } from '@forms/form-personnel/form-personnel.service';
import { FormCompleteWorkOrderSettings } from '@forms-models/form-complete-work-order-settings';
import { FormCompleteWorkOrderSettingsShort } from '@forms-models/form-complete-work-order-settings-short';
import { FormDynamicData } from '@forms-models/form-dynamic-data';
import { DynamicPatch } from '@forms-models/form-dynamic-patch';
import { FormEquipmentSettings } from '@forms-models/form-equipment';
import { FormInspectionSettings } from '@forms-models/form-inspection-settings';
import { FormInspectionSettingsShort } from '@forms-models/form-inspection-settings-short';
import { FormPickUpSettings } from '@forms-models/form-pick-up';
import { FormProcurementSettings } from '@forms-models/form-procurement';
import {
  FormPurchaseOrderSettings,
  FormPurchaseOrderSettingsShort,
} from '@forms-models/form-purchase-order';
import { FormReportMaintenanceSettings } from '@forms-models/form-report-maintenance-settings';
import { FormReportMaintenanceSettingsShort } from '@forms-models/form-report-maintenance-settings-short';
import { FormRequestSettings } from '@forms-models/form-request-settings';
import { FormRequestSettingsShort } from '@forms-models/form-request-settings-short';
import {
  FormReviewRequestSettings,
  FormReviewRequestSettingsShort,
} from '@forms-models/form-review-request-settings';
import { FormReviewWorkOrderSettings } from '@forms-models/form-review-work-order-settings';
import { FormWorkOrderLineSettings } from '@forms-models/form-work-order-line-settings';
import { FormWorkOrderLineSettingsShort } from '@forms-models/form-work-order-line-settings-short';
import { FormWorkOrderSettings } from '@forms-models/form-work-order-settings';
import { FormWorkOrderSettingsShort } from '@forms-models/form-work-order-settings-short';
import { ProcurementState } from '@forms-models/procurement';
import { SuggestedTask } from '@forms-models/suggested-task';
import { WorkOrderLineWithRequirements } from '@forms-models/work-order-line-with-requirements';
import { FormOptions } from '@forms-utils/form-options.enum';
import { SubscriptionsComponent } from '@helpers/subscriptions-component';
import { Priority } from '@models/priority';
import { LookUpService } from '@request-services/look-up.service';
import { TAGLookUpEntity } from '@request-services/tag-lookup-entity';
import { TagSetupGQL } from '@shared/apollo/queries/tag-setup';
import { SaveSession } from '@stores-actions/authentication.action';
import { AddI18nNotification } from '@stores-actions/notification.action';
import { GetWorkOrderLinesByWorkOrder } from '@stores-actions/work-order-line.action';
import { AuthState } from '@stores-states/authentication.state';
import { FeedbackStateObject } from '@stores-states/feedback.state';
import { RequirementStateObject } from '@stores-states/requirement.state';
import { WorkOrderLineState } from '@stores-states/work-order-line.state';
import { toSignal } from '@angular/core/rxjs-interop';
import { PermissionsService } from '@shared-services/permissions.service';
import {
  BatchWorkOrderLinePost,
  UserConfig,
  UserConfigInfo,
  WorkOrderDocumentType,
  WorkOrderLineUsage,
} from '@api/types';
import { PriorityRanksGQL } from '@shared/apollo/queries/priority-rank';
import { MaintenanceTypesGQL } from '@shared/apollo/queries/maintenance-type';
import { OrderTypesGQL } from '@shared/apollo/queries/order-type';
import { FailureCodesGQL } from '@shared/apollo/queries/failure-code';
import { PersonnelGroupsGQL } from '@shared/apollo/queries/personnel-group';
import { WorkCodesGQL } from '@shared/apollo/queries/work-code';
import { WorkOrderTemplatesGQL } from '@shared/apollo/queries/work-order-template';
import { StatusCodesGQL } from '@shared/apollo/queries/status-code';
import { EnterprisesGQL } from '@shared/apollo/queries/enterprise';
import { RegionsGQL } from '@shared/apollo/queries/region';
import { EquipmentGroupsGQL } from '@shared/apollo/queries/equipment-group';
import { CriticalitiesGQL } from '@shared/apollo/queries/criticality';
import { LinesGQL } from '@shared/apollo/queries/line';
import { EquipmentSubGroupsGQL } from '@shared/apollo/queries/equipment-sub-group';
import { WorkProceduresGQL } from '@shared/apollo/queries/work-procedure';
import { MainCodesGQL } from '@shared/apollo/queries/main-code';
import { PrimaryCodesGQL } from '@shared/apollo/queries/primary-code';
import { SecondaryCodesGQL } from '@shared/apollo/queries/secondary-code';
import { AttachmentsGQL } from '@shared/apollo/queries/attachment';
import { UnitOfMeasuresGQL } from '@shared/apollo/queries/unit-of-measure';
import { BinsGQL } from '@shared/apollo/queries/bin';
import { NoSeriesGQL } from '@shared/apollo/queries/no-serie';
import { WorkOrderLinesGQL } from '@shared/apollo/queries/work-order-line';

/* eslint-disable @typescript-eslint/naming-convention */
interface BinAndLocation {
  binCode: string | undefined;
  locationCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormService extends SubscriptionsComponent implements OnDestroy {
  @Select(AuthState.session) session$!: Observable<UserConfig>;
  private readonly permissions = toSignal(
    this.store.select(AuthState.userInfo)
  );

  isContinueEditingTemplateWO = false;
  formDynamicData$ = new BehaviorSubject<FormDynamicData>(
    {} as FormDynamicData
  );
  ngForm: UntypedFormGroup | null = null;
  selectedRequirements$ = new BehaviorSubject<RelatedRequirement[]>([]);
  cardLocationData$ = new BehaviorSubject<CardLocation>({} as CardLocation);
  isProcurement$ = new BehaviorSubject<string>(ProcurementState.off);
  unselectedUsage$ = new BehaviorSubject<string>('');
  regExTimeInput = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/;
  priorities: Priority[] = [
    {
      text: 'Critical',
      id: 1,
      color: '#DE8B94',
    },
    {
      text: 'Emergency',
      id: 2,
      color: '#EC9D7F',
    },
    {
      text: 'Urgent',
      id: 3,
      color: '#F3BF5D',
    },
    {
      text: 'Routine',
      id: 4,
      color: '#9CAFD1',
    },
    {
      text: 'Special Attention',
      id: 5,
      color: '#C1A5CD',
    },
    {
      text: 'Deferred',
      id: 6,
      color: '#B5B7BB',
    },
  ];

  /**
   * Cached data of form service
   */
  cachedData = new Map();

  /**
   * Destroy$  of form service
   */
  private destroy$: Subject<boolean> = new Subject<boolean>();

  get company(): string {
    return this.store.selectSnapshot(AuthState.company);
  }

  constructor(
    private permissionsService: PermissionsService,
    private lookupService: LookUpService,
    private readonly store: Store,
    private tagSetupGQL: TagSetupGQL,
    private formBuilder: FormBuilder,
    private rankPriorityService: PriorityRanksGQL,
    private formPersonnelService: FormPersonnelService,
    private translocoService: TranslocoService,

    /* Services */
    private maintenanceTypes: MaintenanceTypesGQL,
    private orderTypesService: OrderTypesGQL,
    private failureCodesService: FailureCodesGQL,
    private personnelGroupsService: PersonnelGroupsGQL,
    private workCodesService: WorkCodesGQL,
    private templateService: WorkOrderTemplatesGQL,
    private statusCodesService: StatusCodesGQL,
    private enterprisesService: EnterprisesGQL,
    private regionsService: RegionsGQL,
    private equipmentGroupsService: EquipmentGroupsGQL,
    private equipmentSubGroupsService: EquipmentSubGroupsGQL,
    private criticalityService: CriticalitiesGQL,
    private linesService: LinesGQL,
    private workProceduresService: WorkProceduresGQL,
    private mainCodesService: MainCodesGQL,
    private primaryCodesService: PrimaryCodesGQL,
    private secondaryCodesService: SecondaryCodesGQL,
    private attachmentsService: AttachmentsGQL,
    private unitOfMeasureService: UnitOfMeasuresGQL,
    private binContentsService: BinsGQL,
    private noSeriesService: NoSeriesGQL,
    private wolGQL: WorkOrderLinesGQL
  ) {
    super();
  }

  /* Additional form Data */
  getOrderTypes(): Observable<OrderType[]> {
    return this.orderTypesService
      .watch()
      .valueChanges.pipe(map((res) => res.data.orderTypes.items));
  }

  /**
   * Gets maintenance types
   *
   * @returns maintenance types
   */
  getMaintenanceTypes(): Observable<MaintenanceType[]> {
    return this.maintenanceTypes
      .watch()
      .valueChanges.pipe(map((res) => res.data.maintenanceTypes.items));
  }

  /**
   * Gets failure codes
   *
   * @param equipmentId
   * @returns failure codes
   */
  getFailureCodes(equipmentId: string): Observable<EquipmentFailureCode[]> {
    const filter = `Equipment_ID eq '${equipmentId}'`;

    return this.failureCodesService
      .watch({ filter })
      .valueChanges.pipe(map((res) => res.data.equipmentFailureCodes.items));
  }

  /**
   * Gets rank priority codes
   *
   * @returns rank priority codes
   */
  getRankPriorityCodes(): Observable<PriorityRank[]> {
    return this.rankPriorityService.fetch().pipe(
      map((res) => {
        const priorityRanks = [...res.data.priorityRanks.items];
        priorityRanks.unshift({ code: '' } as any);
        return priorityRanks;
      })
    );
  }

  /**
   * Gets supervisor codes
   *
   * @returns supervisor codes
   */
  getSupervisorCodes(): Observable<Personnel[]> {
    if (this.cachedData.has('supervisorCodes'))
      return of(this.cachedData.get('supervisorCodes'));
    return this.formPersonnelService
      .getSupervisorCodes()
      .pipe(tap((codes) => this.cachedData.set('supervisorCodes', codes)));
  }

  getTagSetups() {
    return this.store.select(AuthState.setup);
  }

  getPersonnelGroups(): Observable<PersonnelGroup[]> {
    return this.personnelGroupsService.fetch().pipe(
      map((res) => res.data.personnelGroups.items),
      catchError((err) => {
        console.error(err);
        return of([]);
      })
    );
  }

  getWorkCodes(): Observable<WorkCode[]> {
    return this.workCodesService
      .fetch()
      .pipe(map((res) => res.data.workCodes.items));
  }

  getWebTemplates(): Observable<WorkOrderTemplate[]> {
    return this.templateService
      .fetch()
      .pipe(map((res) => res.data.workOrderTemplates.items));
  }

  getStatusCodes(workOrder: boolean): Observable<StatusCode[]> {
    const filter = `Type eq '${workOrder ? 'Work Order' : 'Request'}'`;

    return this.statusCodesService
      .fetch({ filter })
      .pipe(map((res) => res.data.statusCodes.items));
  }

  getEnterprises(): Observable<Enterprise[]> {
    return this.enterprisesService
      .fetch()
      .pipe(map((res) => res.data.enterprises.items));
  }

  getRegions(): Observable<Region[]> {
    return this.regionsService
      .fetch()
      .pipe(map((res) => res.data.regions.items));
  }

  getEquipmentLines(area?: string): Observable<Line | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.lines, area) as any;
  }

  getEquipmentGroups(): Observable<EquipmentGroup[]> {
    return this.equipmentGroupsService
      .fetch()
      .pipe(map((res) => res.data.equipmentGroups.items));
  }

  getCriticalities(): Observable<Criticality[]> {
    return this.criticalityService
      .fetch()
      .pipe(map((res) => res.data.criticalitys.items));
  }

  getEquipmentSubGroups(): Observable<EquipmentSubGroup[]> {
    return this.equipmentSubGroupsService
      .fetch()
      .pipe(map((res) => res.data.equipmentSubGroups.items));
  }

  getMainCodes(): Observable<MainCode[]> {
    return this.mainCodesService
      .fetch()
      .pipe(map((res) => res.data.mainCodes.items));
  }

  getPrimaryCodes(): Observable<PrimaryCode[]> {
    return this.primaryCodesService
      .fetch()
      .pipe(map((res) => res.data.primaryCodes.items));
  }

  getSecondaryCodes(): Observable<SecondaryCode[]> {
    return this.secondaryCodesService
      .fetch()
      .pipe(map((res) => res.data.secondaryCodes.items));
  }

  getWorkProcedure(no: string): Observable<WorkProcedure> {
    const filter = `No eq '${no}'`;

    return this.workProceduresService
      .fetch({ filter })
      .pipe(map((res) => res.data.workProcedures.items[0]));
  }

  getLookupPersonnelGroups(): Observable<PersonnelGroup | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.personnelGroups) as any;
  }

  getLookupOrderTypes(): Observable<OrderType | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.orderTypes) as any;
  }

  getLookupMainTypes(): Observable<MaintenanceType | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.maintenanceTypes) as any;
  }

  getLookupMainCodes(): Observable<MainCode | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.maintenanceCodes) as any;
  }

  getLookupStatusCodes(type?: string): Observable<StatusCode | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.status, type) as any;
  }

  getTechnicians(): Observable<Personnel | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.personnels) as any;
  }

  getGLAccounts(): Observable<GLAccount | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.glAccounts) as any;
  }

  getProblemCodes(): Observable<ProblemCode | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.problemCodes) as any;
  }

  getFailureCodesPopUp(): Observable<EquipmentFailureCode | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.failureCodes) as any;
  }

  getUdns(): Observable<DocumentTracking | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.udns) as any;
  }

  getWorkOrderLines(
    workOrderNo?: string | null
  ): Observable<WorkOrderLine | undefined> {
    return this.lookupService.lookUp(
      TAGLookUpEntity.workOrderLines,
      workOrderNo
    ) as any;
  }

  getWorkOrderNo(): Observable<WorkOrder | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.workOrders) as any;
  }

  getPlannedWorkOrderNo(): Observable<WorkOrder | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.plannedWorkOrders) as any;
  }

  getJobs(filter?: string): Observable<Job | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.jobs, filter) as any;
  }

  getWoJobTasks(): Observable<WorkOrder | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.woJobTasks) as any;
  }

  getJobTasks(filter?: string): Observable<JobTaskLine | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.jobTasks, filter) as any;
  }

  getEquipmentId(): Observable<Equipment | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.equipments) as any;
  }

  getVendors(): Observable<Vendor | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.vendors) as Observable<
      Vendor | undefined
    >;
  }

  getBinCodes(binCodeFilter?: string): Observable<BinContent | undefined> {
    return this.lookupService.lookUp(
      TAGLookUpEntity.binCodes,
      binCodeFilter
    ) as Observable<BinContent | undefined>;
  }

  getLocations(itemNo?: string): Observable<Location | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.locations, itemNo) as any;
  }

  getItems(vendorNo: string = ''): Observable<Item | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.items, vendorNo) as any;
  }

  getTaEmployee(): Observable<Employee | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.employees) as any;
  }

  getWorkProcedures(type?: string): Observable<WorkProcedure | undefined> {
    return this.lookupService.lookUp(
      TAGLookUpEntity.workProcedures,
      type
    ) as any;
  }

  getFacilities(): Observable<Facility | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.facilities) as any;
  }
  getAreas(): Observable<Area | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.areas) as any;
  }
  getEquipmentAttachments(id: string): Observable<Attachment[]> {
    return this.attachmentsService
      .watch({
        filter: `Source_Type eq 'Equipment' and No eq '${id}'`,
      })
      .valueChanges.pipe(map((res) => res.data.attachments.items));
  }
  getOSP(): Observable<any> {
    return this.lookupService.lookUp(TAGLookUpEntity.osp);
  }

  getWorkOrderTemplate(): Observable<WorkOrderTemplate | undefined> {
    return this.lookupService.lookUp(TAGLookUpEntity.workOrderTemplates) as any;
  }

  getAllWorkOrderLinesFromWorkOrder(
    no: string,
    documentType: WorkOrderDocumentType = WorkOrderDocumentType.released
  ): Observable<WorkOrderLine[]> {
    return this.wolGQL
      .fetch({
        filter: `Work_Order_No eq '${no}' and Document_Type eq '${documentType}'`,
      })
      .pipe(
        map((res) => res.data.workOrderLines.items),
        take(1)
      );
  }

  /* Utilities for the forms */

  mapWolsFromTasks(tasks: SuggestedTask[], actual = true): WorkOrderLine[] {
    return tasks.map(
      (task): WorkOrderLine =>
        ({
          // id: `${task.no}-${task.line}`,
          documentType: WorkOrderDocumentType.released,
          workOrderNo: task.no,
          lineNo: task.existing ? task.line : 0,
          description: task.description ?? '',
          estimatedTime: actual ? task.estimatedTime : task.duration,
          actualTime: actual ? task.duration : 0,
          workProcedureStep: task.workProcedure,
          failureCode: task.failureCode,
          finished: task.finished ?? false,
          equipmentId: task.equipId,
          technicianCode: task.technicianCode,
          resultsInputRequired: task.meterRequired,
          resultTestDescription: task.meterDescription,
          resultsAccepted: task.meterAccepted ?? false,
          currentReading: task.meterCurrent,
          unitOfMeasure: task.meterUnit,
          resultType: task.meterType,
          resultDatetime: task.meterDate,
          resultsValue: task.meterValue,
          workCode: task.workCode,
          // NAV is not able to interpret null, we need to send a fake date string to NAV.
          startingDatetime:
            task.startingDate === null
              ? ('0001-01-01T00:00:00' as any)
              : task.startingDate,
          binaryNegativeValue: task.binaryNegativeValue,
          binaryPositiveValue: task.binaryPositiveValue,
          jobNo: task.jobNo,
          jobTaskNo: task.jobTaskNo,
          jobDescription: task.jobDescription,
          jobTaskDescription: task.jobTaskDescription,
          jobLineType: task.jobLineType,
        } as WorkOrderLine)
    );
  }

  mapBatchWolPayloadFromTasks(
    tasks: SuggestedTask[],
    requirements: RelatedRequirement[],
    actual = true
  ): BatchWorkOrderLinePost[] {
    return tasks.map((task): BatchWorkOrderLinePost => {
      const reqs = requirements.filter(
        (req) => req.sourceNo === task.no && req.sourceLineNo === task.line
      );
      return {
        document_Type: WorkOrderDocumentType.released,
        work_Order_No: task.no,
        line_No: task.existing ? task.line : 0,
        description: task.description,
        estimated_Time: task.duration,
        actual_Time: actual ? task.duration : 0,
        work_Procedure_Step: task.workProcedure,
        failure_Code: task.failureCode,
        finished: task.finished,
        equipment_ID: task.equipId,
        technician_Code: task.technicianCode,
        results_Input_Required: task.meterRequired,
        result_Test_Description: task.meterDescription,
        results_Accepted: task.meterAccepted ?? false,
        current_Meter: task.meterCurrent,
        result_Type: task.meterType,
        result_Datetime: task.meterDate,
        results_Value: task.meterValue,
        work_Code: task.workCode,
        // NAV is not able to interpret null, we need to send a fake date string to NAV.
        starting_Datetime:
          task.startingDate === null
            ? ('0001-01-01T00:00:00' as any)
            : task.startingDate,
        job_No: task.jobNo,
        job_Task_No: task.jobTaskNo,
        job_Line_Type: task.jobLineType,
        usages: this.mapUsagesPayloadFromUsages(reqs),
      };
    });
  }

  mapBatchWolPayloadFromWols(tasks: WorkOrderLine[]): BatchWorkOrderLinePost[] {
    return tasks.map(
      (task): BatchWorkOrderLinePost => ({
        document_Type: WorkOrderDocumentType.released,
        work_Order_No: task.workOrderNo,
        line_No: task.lineNo,
        description: task.description,
        estimated_Time: task.estimatedTime,
        actual_Time: task.actualTime,
        work_Procedure_Step: task.workProcedureStep,
        failure_Code: task.failureCode,
        finished: task.finished,
        equipment_ID: task.equipmentId,
        technician_Code: task.technicianCode,
        results_Input_Required: task.resultsInputRequired,
        result_Test_Description: task.resultTestDescription,
        results_Accepted: task.resultsAccepted,
        current_Meter: task.currentReading,
        result_Type: task.resultType,
        result_Datetime: task.resultDatetime,
        results_Value: task.resultsValue,
        work_Code: task.workCode,
        // NAV is not able to interpret null, we need to send a fake date string to NAV.
        starting_Datetime: task.startingDatetime,
        job_No: task.jobNo,
        job_Task_No: task.jobTaskNo,
        job_Line_Type: task.jobLineType,
        usages: [],
      })
    );
  }

  mapUsagesPayloadFromUsages(
    usages: RelatedRequirement[]
  ): WorkOrderLineUsage[] {
    return usages.map(
      (usage): WorkOrderLineUsage => ({
        source_No: usage.sourceNo,
        source_Line_No: usage.sourceLineNo,
        no: usage.no,
        line_No: usage.lineNo,
        document_Type: usage.documentType,
        actual_Quantity: usage.actualQuantity,
        expected_Quantity: usage.expectedQuantity,
        type: usage.type,
        actual_Unit_Cost: usage.actualUnitCost,
        bin_Code: usage.binCode,
        description_2: usage.description2,
        description: usage.description,
        entry_Type: usage.entryType,
        facility: usage.facility,
        location_Code: usage.locationCode,
        lookup_Type: usage.lookupType,
        pending_Quantity: usage.pendingQuantity,
        post: usage.post,
        posted_Quantity: usage.postedQuantity,
        requirement_Details: usage.requirementDetails,
        unit_of_Measure: usage.unitOfMeasure,
        variant_Code: usage.variantCode,
        vendor_No: usage.vendorNo,
        wo_Reference_Info: usage.woReferenceInfo,
      })
    );
  }

  mapTasksFromWols(
    wols: WorkOrderLine[],
    resetDuration = false,
    useEstimated = false
  ): SuggestedTask[] {
    return wols.map((wol): SuggestedTask => {
      const duration = resetDuration
        ? 0
        : useEstimated
        ? wol.estimatedTime
        : wol.actualTime;
      return {
        no: wol.workOrderNo ?? (Math.random() * 1000).toString(),
        line: wol.lineNo || 0,
        description: wol.description,
        duration,
        postedDuration: (wol.recordedTimeQty ?? 0) + (wol.postedTimeQty ?? 0),
        type: '',
        disabled: this.permissionsService.canEditWorkOrderLine(wol)()
          ? false
          : true,
        workProcedure: wol.workProcedureStep ?? '',
        finished: wol.finished,
        failureCode: wol.failureCode ?? '',
        equipId: wol.equipmentId ?? '',
        technicianCode: wol.technicianCode ?? '',
        startingDate: wol.startingDatetime,
        meterRequired: wol.resultsInputRequired ?? false,
        meterAccepted: wol.resultsAccepted,
        meterCurrent: wol.currentReading ?? 0,
        estimatedTime: wol.estimatedTime,
        meterDescription: wol.resultTestDescription ?? '',
        meterValue: wol.resultsValue ?? '',
        meterUnit: wol.unitOfMeasure ?? '',
        meterType: wol.resultType ?? '',
        previousMeter: wol.currentReading,
        meterDate: wol.resultsValue
          ? wol.resultDatetime || new Date()
          : new Date(),
        workCode: wol.workCode ?? '',
        existing: true,
        usingEstimatedDuration: useEstimated,
        defaultFacility: wol.facility,
        binaryNegativeValue: wol.binaryNegativeValue ?? '',
        binaryPositiveValue: wol.binaryPositiveValue ?? '',
        fromLineNo: wol.fromLineNo ?? 0,
        jobNo: wol.jobNo ?? '',
        jobTaskNo: wol.jobTaskNo ?? '',
        jobDescription: wol.jobDescription ?? '',
        jobTaskDescription: wol.jobTaskDescription ?? '',
        jobLineType: wol.jobLineType ?? '',
      };
    });
  }

  getDefaultBinCode(req: RequirementStateObject) {
    const filter =
      `Item_No eq '${req.no}'` +
      (req.locationCode ? ` and Location_Code eq '${req.locationCode}'` : '');
    return this.binContentsService
      .watch({
        filter,
      })
      .valueChanges.pipe(
        map((res) => res.data.binContents.items),
        tap((binContents) => {
          if (binContents.length) {
            const codes = this.getBinAndLocationCodes(req, binContents);
            req.binCode = codes.binCode ?? '';
            req.locationCode = codes.locationCode;
          }
        })
      );
  }

  generateRequirementKeys(reqs: RelatedRequirement[]): RelatedRequirement[] {
    return reqs.map((req) => ({
      ...req,
      usageNo: this.getRequirementKey(req),
    }));
  }

  getRequirementKey(req: RelatedRequirement): string {
    return `${req.sourceNo}&${req.sourceLineNo}&${req.lineNo ?? req.no}&${
      req.type
    }&${req.variantCode}&${req.requirementDetails}`;
  }

  getNoteKey(note: FeedbackStateObject): string {
    return `${note.documentNo}${note.documentLineNo}${note.lineNo}${note.type}`;
  }

  getTotalActualUsage(reqs: RelatedRequirement[]): number {
    let total = 0;
    reqs.forEach(
      (req) =>
        (total +=
          (req.actualQuantity ?? 0) +
          (req.pendingQuantity ?? 0) +
          (req.postedQuantity ?? 0))
    );
    return total;
  }

  getTotalEstimatedTime(wols: WorkOrderLine[]): number {
    let total = 0;
    wols.forEach((wol) => (total += wol.estimatedTime ?? 0));
    return total;
  }

  timeDifference(current: Date, previous: Date): number {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const elapsed = current.valueOf() - previous.valueOf();
    if (elapsed < msPerMinute) {
      return 0;
    } else {
      return Math.round(elapsed / msPerDay);
    }
  }

  getTimeFromDate(date?: Date): string {
    const currentDate = date ? new Date(date) : new Date();
    const paddingHours = this.getPaddingNumberAsString(currentDate.getHours());
    const paddingMinutes = this.getPaddingNumberAsString(
      currentDate.getMinutes()
    );
    const paddingSeconds = this.getPaddingNumberAsString(
      currentDate.getSeconds()
    );
    return `${paddingHours}:${paddingMinutes}:${paddingSeconds}`;
  }

  splitCodeExtractingLastCharacter(inputString: string): string[] {
    const last = inputString.charAt(inputString.length - 1);
    const first = inputString.slice(0, inputString.length - 1);
    const result: string[] = [];
    result.push(first);
    result.push(last);
    return result;
  }

  getDefaultRequester(): string {
    const portalType = this.store.selectSnapshot(AuthState.portalType);
    const currentUser = this.store.selectSnapshot(AuthState.userInfo);
    if (['requester'].includes(portalType.toLowerCase()))
      return currentUser?.user ?? '';
    return currentUser?.defaultRequester ?? '';
  }

  getRequestDefaultValues(): Partial<Request> {
    const tagSetup = this.store.selectSnapshot(AuthState.setup);
    const user: string = this.getDefaultRequester();
    return {
      maintType: tagSetup?.defaultWrkReqMaintType,
      orderType: tagSetup?.defaultOrderTypeWr,
      workCode: tagSetup?.defaultWorkCodeWr,
      priority: tagSetup?.defaultWrkRequestPriority,
      status: tagSetup?.defaultWrkRequestStatus,
      neededByDate: new Date(),
      requester: user,
    };
  }

  getWorkOrderDefaultValues(): Partial<WorkOrder> {
    const tagSetup = this.store.selectSnapshot(AuthState.setup);
    let start = startOfDay(
      this.getShiftedDate(
        this.splitCodeExtractingLastCharacter(tagSetup?.startLeadTime || '')
      )
    );
    start = addMinutes(start, this.minutesStartOfDay(new Date()));

    return {
      documentType: 'Released',
      orderType: tagSetup?.defaultOrderType,
      maintType: tagSetup?.defaultMaintenanceType,
      priority: tagSetup?.defaultPriority,
      workCode: tagSetup?.defaultWorkCode,
      status: tagSetup?.defaultStatus,
      orderDate: new Date(),
      expirationDate: this.getShiftedDate(
        this.splitCodeExtractingLastCharacter(tagSetup?.expireLeadTime || '')
      ),
      startingDatetime: start,
      dueByDate: this.getShiftedDate(
        this.splitCodeExtractingLastCharacter(tagSetup?.dueByLeadTime || '')
      ),
      requestedServiceDate: this.getShiftedDate(
        this.splitCodeExtractingLastCharacter(tagSetup?.rsdLeadTime || '')
      ),
    };
  }

  minutesStartOfDay(date: Date): number {
    return date.getHours() * 60 + date.getMinutes();
  }

  getShiftedDate(inputArray: string[]): Date {
    let currentDate = new Date();
    switch (inputArray[1].toLowerCase()) {
      case 'd':
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + parseInt(inputArray[0], 10)
        );
        break;
      case 'm':
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + parseInt(inputArray[0], 10),
          currentDate.getDate()
        );
        break;
      case 'y':
        currentDate = new Date(
          currentDate.getFullYear() + parseInt(inputArray[0], 10),
          currentDate.getMonth(),
          currentDate.getDate()
        );
        break;
      default:
        break;
    }
    return currentDate;
  }

  /**
   * Marks form as dirty
   *
   * @param formGroup The form group to mark as dirty.
   * @param [exceptions] Array of control name that are not be be marked as dirty.
   */
  markFormAsDirty(formGroup: UntypedFormGroup, exceptions?: string[]): void {
    Object.entries(formGroup.controls).forEach(([key, control]) => {
      let exception = false;
      if (exceptions) {
        exception = exceptions.some((x) => x === key);
      }
      if (control.value && !exception) control.markAsDirty();
    });
  }

  /**
   * Parses form options and will always return Full Forms. Use the parseSimpleFormOptions method to get the Small Forms.
   *
   * @param formOption
   * @returns form options
   * @variation parseSimpleFormOptions
   */
  parseFormOptions<T>(formOption: FormOptions, object: T) {
    const session = this.store.selectSnapshot(AuthState.session);
    const permissions = this.store.selectSnapshot(AuthState.permissions);

    switch (formOption) {
      case FormOptions.reportMaintenance:
        return 1;
      case FormOptions.editTask:
        if (session?.workOrderLine?.form?.type === 'long') return 2;
        else return 26;
      case FormOptions.createTask:
        if (session?.workOrderLine?.form?.type === 'long') return 3;
        else return 25;
      case FormOptions.editWorkOrder:
        if (
          permissions &&
          !this.permissionsService.canEditWorkOrder(object as WorkOrder)()
        )
          return this.formOptionNotAllowed();
        if (session?.workOrder?.form?.type === 'long') return 4;
        else return 24;
      case FormOptions.createWorkOrder:
        if (permissions && !this.permissionsService.canCreateWorkOrder())
          return this.formOptionNotAllowed();
        if (session?.workOrder?.form?.type === 'long') return 5;
        else return 23;
      case FormOptions.reportInspection:
        if (permissions && !permissions.allowEditRequest)
          return this.formOptionNotAllowed();
        return 6;
      case FormOptions.editRequest:
        if (permissions && !permissions.allowEditRequest)
          return this.formOptionNotAllowed();
        if (session?.request?.form?.type === 'long') return 7;
        else return 22;
      case FormOptions.createRequest:
        if (permissions && !permissions.allowCreateRequest)
          return this.formOptionNotAllowed();
        if (session?.request?.form?.type === 'long') return 8;
        else return 21;
      case FormOptions.cloneEquipment:
      case FormOptions.createSubassembly:
      case FormOptions.createEquipmentFromScratch:
        if (permissions && !permissions.allowCreateEquipment)
          return this.formOptionNotAllowed();
        return 9;
      case FormOptions.editEquipment:
        if (permissions && !permissions.allowEditEquipment)
          return this.formOptionNotAllowed();
        return 10;
      case FormOptions.createEquipmentFromTemplate:
        if (permissions && !permissions.allowCreateEquipment)
          return this.formOptionNotAllowed();
        return 10;
      case FormOptions.logMeterEntry:
        return 11;
      case FormOptions.createWorkOrderFromRequest:
        if (permissions && !this.permissionsService.canCreateWorkOrder())
          return this.formOptionNotAllowed();
        return 14;
      case FormOptions.editItem:
        return 15;
      case FormOptions.createItem:
        return 16;
      case FormOptions.editPersonnel:
        return 17;
      case FormOptions.createPersonnel:
        return 18;
      case FormOptions.editConsumable:
        return 19;
      case FormOptions.createConsumable:
        return 20;
      case FormOptions.completeWorkOrder:
        return 27;
      case FormOptions.createPurchaseOrder:
        if (permissions && !this.permissionsService.canCreatePurchaseOrder())
          return this.formOptionNotAllowed();
        return 28;
      case FormOptions.editPurchaseOrder:
        return 29;
      case FormOptions.pickUp:
        return 30;
      case FormOptions.reviewWorkOrder:
        return 31;
      case FormOptions.reviewRequest:
        return 32;
      case FormOptions.reviewTimesheet:
        return 33;
      case FormOptions.editWorkOrders:
        return 34;
      case FormOptions.createProcurement:
        if (permissions && !this.permissionsService.canCreatePurchaseOrder())
          return this.formOptionNotAllowed();
        return 35;
      case FormOptions.createCrew:
        return 36;
      case FormOptions.editCrew:
        return 37;
      case FormOptions.foremanDispatch:
        return 38;
      case FormOptions.editPlannedWorkOrder:
        return 39;
      case FormOptions.formDataEntry:
        return 40;
      default:
        return 0;
    }
  }

  getFormSettingOptions(
    formOption: FormOptions
  ): Record<string, any> | undefined {
    const session: UserConfig = JSON.parse(
      JSON.stringify(this.store.selectSnapshot(AuthState.session))
    );

    switch (formOption) {
      case FormOptions.editTask:
      case FormOptions.createTask:
        return merge(
          new FormWorkOrderLineSettings('short'),
          session?.workOrderLine?.form
        );
      case FormOptions.editPlannedWorkOrder:
      case FormOptions.editWorkOrder:
      case FormOptions.createWorkOrder:
        return merge(
          new FormWorkOrderSettings('short'),
          session?.workOrder?.form
        );
      case FormOptions.editRequest:
      case FormOptions.createRequest:
        return merge(new FormRequestSettings('short'), session?.request?.form);
      case FormOptions.reportMaintenance:
        return merge(
          new FormReportMaintenanceSettings('short'),
          session?.reportMaintenance?.form
        );
      case FormOptions.completeWorkOrder:
        return merge(
          new FormCompleteWorkOrderSettings('short'),
          session?.reportWorkOrder?.form
        );
      case FormOptions.reportInspection:
        return merge(
          new FormInspectionSettings('short'),
          session?.reportInspection?.form
        );
      case FormOptions.editPurchaseOrder:
      case FormOptions.createPurchaseOrder:
        return merge(
          new FormPurchaseOrderSettings('short'),
          session?.purchaseOrder?.form
        );
      case FormOptions.createProcurement:
        return merge(
          new FormProcurementSettings('short'),
          session?.procurement?.form
        );
      case FormOptions.pickUp:
        return merge(
          new FormPickUpSettings('short'),
          session?.purchasePickup?.form
        );
      case FormOptions.reviewRequest:
        return merge(
          new FormReviewRequestSettings('short'),
          session?.reviewRequest?.form
        );
      case FormOptions.reviewWorkOrder:
        return merge(
          new FormReviewWorkOrderSettings('short'),
          session?.reviewWorkOrder?.form
        );
      case FormOptions.cloneEquipment:
      case FormOptions.createSubassembly:
      case FormOptions.createEquipmentFromScratch:
      case FormOptions.editEquipment:
      case FormOptions.createEquipmentFromTemplate:
        return merge(
          new FormEquipmentSettings('short'),
          session?.equipment?.form
        );
      default:
        return;
    }
  }

  saveFormSettingOptions(
    settingObject: Record<string, any>,
    formOption: FormOptions
  ): Observable<any> {
    const session: UserConfig =
      structuredClone(this.store.selectSnapshot(AuthState.session)) ??
      ({} as UserConfig);

    console.log('session', session);
    switch (formOption) {
      case FormOptions.editTask:
      case FormOptions.createTask:
        if (!session.workOrderLine)
          session.workOrderLine = {} as UserConfigInfo;
        session.workOrderLine.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.editWorkOrder:
      case FormOptions.editPlannedWorkOrder:
      case FormOptions.createWorkOrder:
        if (!session.workOrder) session.workOrder = {} as UserConfigInfo;
        session.workOrder.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.editRequest:
      case FormOptions.createRequest:
        if (!session.request) session.request = {} as UserConfigInfo;
        session.request.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.reportMaintenance:
        if (!session.reportMaintenance)
          session.reportMaintenance = {} as UserConfigInfo;
        session.reportMaintenance.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.completeWorkOrder:
        if (!session.reportWorkOrder)
          session.reportWorkOrder = {} as UserConfigInfo;
        session.reportWorkOrder.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.reportInspection:
        if (!session.reportInspection)
          session.reportInspection = {} as UserConfigInfo;
        session.reportInspection.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.createProcurement:
        if (!session.procurement) session.procurement = {} as UserConfigInfo;
        session.procurement.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.editPurchaseOrder:
      case FormOptions.createPurchaseOrder:
        if (!session.purchaseOrder)
          session.purchaseOrder = {} as UserConfigInfo;
        session.purchaseOrder.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.pickUp:
        if (!session.purchasePickup)
          session.purchasePickup = {} as UserConfigInfo;
        session.purchasePickup.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.reviewRequest:
        if (!session.reviewRequest)
          session.reviewRequest = {} as UserConfigInfo;
        session.reviewRequest.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.reviewWorkOrder:
        if (!session.reviewWorkOrder)
          session.reviewWorkOrder = {} as UserConfigInfo;
        session.reviewWorkOrder.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      case FormOptions.cloneEquipment:
      case FormOptions.createSubassembly:
      case FormOptions.createEquipmentFromScratch:
      case FormOptions.editEquipment:
      case FormOptions.createEquipmentFromTemplate:
        if (!session.equipment) session.equipment = {} as UserConfigInfo;
        session.equipment.form = settingObject;
        return this.store.dispatch(new SaveSession(session));
      default:
        return of();
    }
  }

  getFormSuggestedTaskSettings(documentType: string): any {
    let settings: any;
    const session = this.store.selectSnapshot(AuthState.session);
    switch (documentType) {
      case 'request':
        settings = merge(
          new FormRequestSettingsShort(),
          session?.request?.form?.short
        );
        break;
      case 'reviewRequest':
        settings = merge(
          new FormReviewRequestSettingsShort(),
          session?.reviewRequest?.form?.short
        );
        break;
      case 'workOrder':
        settings = merge(
          new FormWorkOrderSettingsShort(),
          session?.workOrder?.form?.short
        );
        break;
      case 'completeWorkOrder':
        settings = merge(
          new FormCompleteWorkOrderSettingsShort(),
          session?.reportWorkOrder?.form?.short
        );
        break;
      case 'workOrderLine':
        settings = merge(
          new FormWorkOrderLineSettingsShort(),
          session?.workOrderLine?.form?.short
        );
        break;
      case 'inspection':
        settings = merge(
          new FormInspectionSettingsShort(),
          session?.reportInspection?.form?.short
        );
        break;
      case 'maintenance':
        settings = merge(
          new FormReportMaintenanceSettingsShort(),
          session?.reportMaintenance?.form?.short
        );
        break;
      case 'purchaseOrder':
        settings = merge(
          new FormPurchaseOrderSettingsShort(),
          session?.purchaseOrder?.form?.short
        );
        break;
      default:
        break;
    }
    return settings;
  }

  getManualIdEntryIsAllowedFor(code: string): Observable<boolean | undefined> {
    return this.noSeriesService
      .watch({ filter: `Code eq '${code}'` })
      .valueChanges.pipe(
        map((response) => response.data.noSeries.items[0].manualNos)
      );
  }

  /**
   * Gets dirty values of your form using the form fields as keys. Make sure your form fields are named asccordingly to your object model.
   *
   * @param form
   * @param includeSubForms Dirty values will be generated for the subforms as well.
   * @param excludeObjects Excluded Objects that won't be returned.
   * @returns dirty values
   */
  getDirtyValues(
    form: UntypedFormGroup,
    includeSubForms?: boolean,
    excludeObjects?: string[]
  ): Record<string, any> {
    const dirtyValues: any = {};
    Object.keys(form.controls).forEach((key: any) => {
      const currentControl = form.controls[key];
      const excluded = excludeObjects?.includes(key) ?? false;
      if (!currentControl.dirty || excluded) return;
      if (
        includeSubForms &&
        currentControl.value &&
        typeof currentControl.value === 'object' &&
        Object.keys(currentControl.value)[0]
      ) {
        const subKey = Object.keys(currentControl.value)[0];
        const subValue = Object.values(currentControl.value)[0];
        return (dirtyValues[subKey] = subValue);
      }
      dirtyValues[key] = currentControl.value;
      return;
    });

    return dirtyValues;
  }

  /**
   * Generates post object from values.
   *
   * @param formValues
   * @param workOrderNo
   * @returns post object
   */
  generateWorkOrderLinePostObject(
    formValues: Partial<WorkOrderLine>,
    workOrderNo: string = ''
  ): Partial<WorkOrderLine> {
    formValues.workOrderNo = workOrderNo;
    return formValues;
  }

  /**
   * Generates post object from values for full batc Work ORder LIne creation.
   *
   * @param formValues
   * @param workOrderNo
   * @returns post object
   */
  generateFullWorkOrderLinePostObject(
    values: WorkOrderLineWithRequirements,
    workOrderNo: string = ''
  ): BatchWorkOrderLinePost {
    const usages = this.mapUsagesPayloadFromUsages(values.requirements);
    const feedback = values.additionalNote
      ? {
          feedback_Text: values.additionalNote,
          feedback_Type: 'General',
          failure_Code: '',
        }
      : undefined;

    // TODO: replace by BatchWorkOrderLinePost type when available
    const payload: any = {
      area: values.workOrderLine.area,
      region: values.workOrderLine.region,
      facility: values.workOrderLine.facility,
      enterprise: values.workOrderLine.enterprise,
      line_No: values.workOrderLine.lineNo,
      feedback,
      usages,
      document_Type: WorkOrderDocumentType.released,
      personnel_Group: values.workOrderLine.personnelGroup,
      work_Order_No: workOrderNo,
      work_Procedure_Step: values.workOrderLine.workProcedureStep,
      finished: values.workOrderLine.finished,
      description: values.workOrderLine.description,
      estimated_Time: values.workOrderLine.estimatedTime,
      equipment_ID: values.workOrderLine.equipmentId,
      expiration_Date: values.workOrderLine.expirationDate,
      due_By_Date: values.workOrderLine.dueByDate,
      requested_Service_Date: values.workOrderLine.requestedServiceDate,
      starting_Datetime: values.workOrderLine.startingDatetime,
      technician_Code: values.workOrderLine.technicianCode,
      work_Code: values.workOrderLine.workCode,
      failure_Code: values.workOrderLine.failureCode,
      job_No: values.workOrderLine.jobNo,
      job_Task_No: values.workOrderLine.jobTaskNo,
      job_Line_Type: values.workOrderLine.jobLineType,
    };
    return payload;
  }

  /**
   * Generates patch object from values. Only works for updating.
   *
   * @param formValues
   * @returns patch object
   */
  generatePatchObject(
    formValues: Record<string, string | number>
  ): Operation[] {
    const patchObject: Operation[] = [];
    for (const [key, value] of Object.entries(formValues)) {
      if (value || value === '' || value === 0)
        patchObject.push({ op: 'replace', path: `/${key}`, value });
    }
    return patchObject;
  }

  /**
   * Validates proper date, it will not show "null" NAV dates and breakign the UI.
   *
   * @param date
   * @returns proper date
   */
  validateProperDate(date: any) {
    //  Invalid date string from BC includes: '0001-01-01T00:00:00.000Z'  '0001-01-01T00:00:00'
    if (typeof date === 'string' && !date.startsWith('0001-01-01T00:00:00'))
      return date;
    if (typeof date === 'object' && date instanceof Date) return date;
    else return '';
  }

  /**
   * Generates dynamic patches that can be used to automated calls to Tag API.
   *
   * @param originalRecords Original records before any modification.
   * @param updatedRecord Updated records.
   * @param id The field id to be used to compare those records.
   * @param exceptions Array of fields name to not consider in the patch generation.
   * @returns A array of dynamic patches.
   */
  generateDynamicPatches(
    originalRecords: Record<string, any>[],
    updatedRecord: Record<string, any>[],
    id: string,
    exceptions?: string[]
  ): DynamicPatch[] {
    const dynamicPatch: DynamicPatch[] = [];
    updatedRecord.forEach((uRec) => {
      const i = originalRecords.findIndex((oRec) => oRec[id] === uRec[id]);
      if (~i) {
        let patch = compare(originalRecords[i], uRec);
        patch = patch.filter(
          (x) => !exceptions?.some((exception) => '/' + exception === x.path)
        );
        if (patch.length === 0) return;

        dynamicPatch.push({ object: uRec, type: 'update', patch });
      } else dynamicPatch.push({ object: uRec, type: 'add', patch: [] });
    });

    originalRecords.forEach((oRec) => {
      const i = updatedRecord.findIndex((uRec) => uRec[id] === oRec[id]);
      if (!~i) dynamicPatch.push({ object: oRec, type: 'delete', patch: [] });
    });
    return dynamicPatch;
  }

  // Stand Forms used accross form components.

  /**
   * Determines whether the user has 'Full Access' across all access levels for work order lines.
   * This method checks if every permission in the provided object equals 'Full Access'.
   *
   * @param wolAccess An object containing access levels for work order lines, such as create, read, update, and delete.
   * @returns true if all access levels are 'Full Access', otherwise false.
   */
  isFullAccess(wolAccess: any): boolean {
    return Object.values(wolAccess).every((access) => access === 'Full Access');
  }

  initWorkOrderLineFormGroup(
    formType: 'patch' | 'post',
    small: boolean,
    workOrderLine?: WorkOrderLine
  ) {
    const timeSheet = this.store.selectSnapshot(
      AuthState.userInfo
    )?.useConsumeTimesheet;
    const wolAccessLevel = this.permissionsService.workOrderLinePermissions();
    const fullAccess = this.isFullAccess(wolAccessLevel);
    const formGroup = this.formBuilder.group({
      document_Type: [
        { value: 'Released', disabled: true },
        [Validators.required],
      ],
      equipment_ID: [workOrderLine?.equipmentId, [Validators.required]],
      location: [workOrderLine?.location],
      technician_Code: [
        {
          value: workOrderLine?.technicianCode,
          disabled: !fullAccess,
        },
      ],
      status: [workOrderLine?.status],
      work_Order_No: [{ value: workOrderLine?.workOrderNo, disabled: true }],
      company_ID_No: [
        { value: workOrderLine?.companyIdNo, disabled: true },
        Validators.maxLength(50),
      ],
      work_Code: [workOrderLine?.workCode],
      maint_Type: [workOrderLine?.maintType, Validators.maxLength(50)],
      finished: [{ value: workOrderLine?.finished, disabled: true }],
      line_No: [{ value: workOrderLine?.lineNo, disabled: true }],
      description: [
        workOrderLine?.description,
        [Validators.required, Validators.maxLength(250)],
      ],
      additionalNote: ['', [Validators.maxLength(250)]],
      personnel_Group: [
        {
          value: workOrderLine?.personnelGroup,
          disabled: !fullAccess,
        },
        Validators.maxLength(50),
      ],
      enterprise: [
        { value: workOrderLine?.enterprise, disabled: true },
        Validators.maxLength(50),
      ],

      area: [
        { value: workOrderLine?.area, disabled: true },
        Validators.maxLength(50),
      ],
      region: [
        { value: workOrderLine?.region, disabled: true },
        Validators.maxLength(50),
      ],
      facility: [workOrderLine?.facility, Validators.maxLength(50)],
      from_Work_Procedure_Step: [
        workOrderLine?.fromWorkProcedureStep,
        Validators.maxLength(50),
      ],
      work_Procedure_Step: [
        { value: workOrderLine?.workProcedureStep, disabled: false },
        Validators.maxLength(50),
      ],
      results_Input_Required: [
        { value: workOrderLine?.resultsInputRequired, disabled: true },
        Validators.maxLength(50),
      ],
      result_Type: [
        { value: workOrderLine?.resultType, disabled: true },
        Validators.maxLength(50),
      ],
      sub_Steps: [workOrderLine?.subSteps, Validators.maxLength(50)],
      creation_Date: [
        {
          value: this.validateProperDate(workOrderLine?.creationDate),
          disabled: true,
        },
        Validators.maxLength(50),
      ],
      order_Date: [
        {
          value: this.validateProperDate(workOrderLine?.orderDate),
          disabled: true,
        },
        Validators.maxLength(50),
      ],
      standard_Time: [workOrderLine?.standardTime, Validators.maxLength(50)],
      expiration_Date: [
        this.validateProperDate(workOrderLine?.expirationDate),
        Validators.maxLength(50),
      ],
      starting_Datetime: [
        this.validateProperDate(workOrderLine?.startingDatetime),
        Validators.maxLength(50),
      ],
      ending_Datetime: [
        this.validateProperDate(workOrderLine?.endingDatetime),
        Validators.maxLength(50),
      ],
      actual_Time: [
        { value: workOrderLine?.actualTime, disabled: timeSheet },
        Validators.maxLength(50),
      ],
      requested_Service_Date: [
        this.validateProperDate(workOrderLine?.requestedServiceDate),
        Validators.maxLength(50),
      ],
      estimated_Time: [
        { value: workOrderLine?.estimatedTime, disabled: !small },
        Validators.maxLength(50),
      ],

      standard_Cost: [
        { value: workOrderLine?.standardCost, disabled: true },
        Validators.maxLength(50),
      ],
      estimated_Cost: [
        { value: workOrderLine?.estimatedCost, disabled: true },
        Validators.maxLength(50),
      ],
      unit_Cost: [
        { value: workOrderLine?.unitCost, disabled: true },
        Validators.maxLength(50),
      ],
      actual_Cost: [
        { value: workOrderLine?.actualCost, disabled: true },
        Validators.maxLength(50),
      ],
      due_By_Date: [
        this.validateProperDate(workOrderLine?.dueByDate),
        Validators.maxLength(50),
      ],

      current_Reading: [
        { value: workOrderLine?.currentReading, disabled: true },
        Validators.maxLength(50),
      ],
      result_Datetime: [
        {
          value: this.validateProperDate(workOrderLine?.resultDatetime),
          disabled: true,
        },
        Validators.maxLength(50),
      ],

      meter_Readings: [
        { value: workOrderLine?.meterReadings, disabled: true },
        Validators.maxLength(50),
      ],
      test_Results: [
        { value: workOrderLine?.testResults, disabled: true },
        Validators.maxLength(50),
      ],
      results_Value: [
        { value: workOrderLine?.resultsValue, disabled: true },
        Validators.maxLength(50),
      ],
      results_Accepted: [
        { value: workOrderLine?.resultsAccepted, disabled: true },
        Validators.maxLength(50),
      ],
      criticality: [{ value: '', disabled: true }, Validators.maxLength(50)],
      priority: [workOrderLine?.priority, Validators.maxLength(50)],
      priority_Rank: [workOrderLine?.priorityRank],

      job_No: [
        { value: workOrderLine?.jobNo, disabled: true },
        Validators.maxLength(50),
      ],
      job_Task_No: [
        { value: workOrderLine?.jobTaskNo, disabled: true },
        Validators.maxLength(50),
      ],
      job_Line_Type: [
        { value: workOrderLine?.jobLineType, disabled: true },
        Validators.maxLength(50),
      ],
    });
    formGroup.markAllAsTouched();
    if (formType === 'post')
      this.markFormAsDirty(formGroup, [
        'line_No',
        'result_Datetime',
        'document_Type',
        // Creates issue when posting something invalid. TODO - Add additional verificator for valid dates.
        'ending_Datetime',
      ]);
    return formGroup;
  }

  getMeasureUnits(): Observable<string[]> {
    return this.unitOfMeasureService.watch().valueChanges.pipe(
      map((response) => {
        return response.data.unitOfMeasures.items.map((unit) => unit.code);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getTopPersonnelGroup(personnel_Group: string | undefined): string {
    if (!personnel_Group) return '';
    const pgs = this.getPersonnelGroupArray(personnel_Group);
    if (pgs.length > 0) return pgs[0];
    return '';
  }

  getPersonnelGroupArray(personnel_Group: string | undefined): string[] {
    if (!personnel_Group) return [];
    return personnel_Group.split('|').map((x) => x.trim());
  }

  validateQuantityForChargeTypeFlat(
    usages: RequirementStateObject[],
    useActual = false
  ): boolean {
    const index = usages.findIndex((usage) => {
      let currentValue = useActual
        ? usage.actualQuantity
        : usage.expectedQuantity;
      currentValue = Number(currentValue);
      return (
        usage.type === 'OSP' &&
        usage.chargeType === 'Flat' &&
        !isNaN(currentValue) &&
        currentValue !== 1
      );
    });
    if (index !== -1) {
      const quantityTranslationKey = useActual
        ? 'actualQuantityKey'
        : 'expectedQuantityKey';
      this.store.dispatch(
        new AddI18nNotification(
          {
            key: 'flatTypeQuantityMustBeEqual1KeyParam',
            params: {
              quantityName: this.translocoService.translate(
                quantityTranslationKey
              ),
              usageNo: usages[index].no,
              currentValue: useActual
                ? usages[index].actualQuantity
                : usages[index].expectedQuantity,
            },
          },
          'error'
        )
      );
      return false;
    }
    return true;
  }

  private getPaddingNumberAsString(d: number): string {
    return d < 10 ? '0' + d.toString() : d.toString();
  }

  private getBinAndLocationCodes(
    req: RequirementStateObject,
    binContents: any[]
  ): BinAndLocation {
    let binContentDefault = binContents.find((x) => x.default);
    if (req.locationCode) {
      const binContentsFiltered = binContents.filter(
        (x) => x.locationCode === req.locationCode
      );
      if (binContentsFiltered.length > 0) {
        binContentDefault = binContentsFiltered.find((x) => x.default);
        return this.getBinAndLocationCode(
          binContentDefault ?? binContentsFiltered[0]
        );
      }
      return { binCode: '', locationCode: '' };
    }
    return this.getBinAndLocationCode(binContentDefault ?? binContents[0]);
  }

  private getBinAndLocationCode(bl: BinAndLocation): BinAndLocation {
    return { binCode: bl.binCode, locationCode: bl.locationCode };
  }

  /**
   * Forms option not allowed
   *
   *
   */
  private formOptionNotAllowed() {
    this.store.dispatch(
      new AddI18nNotification(
        {
          key: 'formOptionNotAllowedKey',
        },
        'error'
      )
    );
  }
}
