import { Injectable } from '@angular/core';
import type { GraphQLParams, PrimaryCode } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  primaryCodes: { items: PrimaryCode[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class PrimaryCodesGQL extends Query<
  Response,
  GraphQLParams<'primaryCode'>
> {
  document = gql(config.primaryCode.query);
}
