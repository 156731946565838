/* eslint-disable @typescript-eslint/naming-convention */

import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
export class FormWorkOrderSettingsShort {
  basic_information = {
    criticality: true,
    document_Tracking_No: false,
    document_Type: false,
    due_By_Date: true,
    enabled: true,
    protected: true,
    expiration_Date: false,
    facility: false,
    job_No: false,
    job_Description: false,
    job_Task_No: false,
    job_Task_Description: false,
    job_Line_Type: false,
    maint_Type: false,
    order_Date: true,
    order_Type: true,
    oSP: false,
    personnel_group: true,
    priority: true,
    priority_Rank: true,
    problem_Code: false,
    requested_Service_Date: true,
    starting_Datetime: true,
    status: true,
    supervisor_Code: false,
    technician_Code: true,
    template_No: false,
    work_Code: false,
  };
  suggested_Tasks = {
    description: true,
    duration: true,
    enabled: true,
    equipment_ID: false,
    finished: true,
    line: true,
    starting_Datetime: false,
    technician_Code: true,
    usage: true,
    work_Procedure: true,
    job_No: false,
    job_Description: false,
    job_Task_No: false,
    job_Task_Description: false,
    job_Line_Type: false,
  };
  requirements = new FormRequirementsModalSettings({ useExpectedQuantity: true });

  insight = {
    enabled: false,
  };
  attachment = {
    enabled: false,
  };
}
