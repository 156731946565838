import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import type { GLAccount, Personnel } from '@tag/graphql';
import { Operation } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthState } from '@stores-states/authentication.state';
import { PersonnelsGQL } from '@shared/apollo/queries/personnel';
import { PersonnelGroupsGQL } from '@shared/apollo/queries/personnel-group';
import { RegionsGQL } from '@shared/apollo/queries/region';
import { FacilitiesGQL } from '@shared/apollo/queries/facility';
import { GlAccountsGQL } from '@shared/apollo/queries/gl-account';

@Injectable({
  providedIn: 'root',
})
export class FormPersonnelService {
  constructor(
    private readonly store: Store,
    private facilitiesService: FacilitiesGQL,
    private personnelsService: PersonnelsGQL,
    private regionsService: RegionsGQL,
    private personnelGroupsService: PersonnelGroupsGQL,
    private glAccountsService: GlAccountsGQL
  ) {}

  get company(): string {
    return this.store.selectSnapshot(AuthState.company) || '';
  }

  getFacilities(): Observable<string[]> {
    return this.facilitiesService
      .watch()
      .valueChanges.pipe(
        map((facilities) =>
          facilities.data.facilities.items.map((y) => y.code ?? '')
        )
      );
  }
  getRegions(): Observable<string[]> {
    return this.regionsService
      .watch()
      .valueChanges.pipe(
        map((regions) => regions.data.regions.items.map((y) => y.code ?? ''))
      );
  }
  getSuppervisorCode(): Observable<string[]> {
    return this.personnelsService
      .watch({ filter: `Personnel_Type eq 'Supervisor'` })
      .valueChanges.pipe(
        map((personnels) =>
          personnels.data.personnels.items.map((y) => y.no ?? '')
        )
      );
  }
  getSupervisorCodes(): Observable<Personnel[]> {
    return this.personnelsService
      .watch({
        filter: `Personnel_Type eq 'Supervisor'`,
      })
      .valueChanges.pipe(map((result) => result.data.personnels.items));
  }
  getPersonnelGroups(): Observable<string[]> {
    return this.personnelGroupsService
      .watch()
      .valueChanges.pipe(
        map((personnelGroups) =>
          personnelGroups.data.personnelGroups.items.map((y) => y.code ?? '')
        )
      );
  }
  getResources(): Observable<string[]> {
    throw new Error('Method not implemented.');
    // return this.resourcesService
    //   .ResourcesGet(
    //     this.company,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     ['No']
    //   )
    //   .pipe(
    //     catchError(() => []),
    //     map((resources: Resource[]) => resources.map((y) => y.no ?? ''))
    //   );
  }
  getZIPCodes(): Observable<string[]> {
    throw new Error('Method not implemented.');
    // return this.zipCodesService
    //   .ZIPCodesGet(
    //     this.company,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     ['Code']
    //   )
    //   .pipe(
    //     catchError(() => []),
    //     map((zipCodes: ZIPCode[]) => zipCodes.map((y) => y.code ?? ''))
    //   );
  }
  getCountries(): Observable<string[]> {
    throw new Error('Method not implemented.');
    // return this.countriesService
    //   .CountriesGet(
    //     this.company,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined
    //   )
    //   .pipe(
    //     catchError(() => []),
    //     map((countries: Country[]) => countries.map((y) => y.code ?? ''))
    //   );
  }
  getGLAccounts(): Observable<string[]> {
    throw new Error('Method not implemented.');
    // return this.glAccountsService
    //   .GLAccountsGet(
    //     this.company,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined,
    //     undefined
    //   )
    //   .pipe(
    //     catchError(() => []),
    //     map((glAccounts: GLAccount[]) => glAccounts.map((y) => y.no ?? ''))
    //   );
  }

  getPersonnelTypes(): Observable<string[]> {
    return of(['Employee', 'Supervisor', 'Contractor', 'Machine']);
  }

  getBillingTypes(): Observable<string[]> {
    return of(['G/L Account', 'Resource']);
  }

  createPersonnel(newPersonnel: Personnel): Observable<Personnel> {
    throw new Error('Method not implemented.');
    // return this.personnelsService.PersonnelsPost(this.company, newPersonnel);
  }

  updatePersonnel(
    personnelNo: string,
    patch: Operation[]
  ): Observable<Personnel> {
    throw new Error('Method not implemented.');
    // return this.personnelsService.PersonnelsCodePatch(
    //   this.company,
    //   personnelNo,
    //   patch
    // );
  }
}
