/* eslint-disable @typescript-eslint/naming-convention */

import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
export class FormWorkOrderLineSettingsShort {
  basic_information = {
    additional_note: true,
    asset: true,
    criticality: true,
    due_By_Date: true,
    facility: false,
    other_data_fields: true,
    personnel_group: true,
    priority: true,
    priority_Rank: true,
    starting_Date: true,
    technician: true,
    work_code: true,
    work_Procedure: true,

    protected: true,
    enabled: true,
  };
  suggested_Tasks = {
    description: true,
    duration: true,
    enabled: true,
    equipment_ID: false,
    finished: false,
    line: true,
    usage: true,
    job_No: false,
    job_Description: false,
    job_Task_No: false,
    job_Task_Description: false,
    job_Line_Type: false,
  };
  requirements = new FormRequirementsModalSettings({ useExpectedQuantity: true });
  additional_Information = {
    enabled: false,
  };
  attachment = {
    enabled: false,
  };
}
