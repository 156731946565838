import { Injectable } from '@angular/core';
import type { EquipmentSubGroup, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  equipmentSubGroups: { items: EquipmentSubGroup[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentSubGroupsGQL extends Query<
  Response,
  GraphQLParams<'equipmentSubGroup'>
> {
  document = gql(config.equipmentSubGroup.query);
}
