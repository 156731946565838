/* eslint-disable @typescript-eslint/naming-convention */
// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.

import { FormProcurementLinesSettings } from './form-procurement-lines-settings';

// To keep consistency with the other models. This one has the short / long type as well but is not used.
export class FormProcurementSettings {
  type = 'short' as const;
  short = new FormProcurementSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}

export class FormProcurementSettingsShort {
  procurement_Lines: FormProcurementLinesSettings = {
    binCode: false,
    description: true,
    enabled: true,
    projAvailBalance: false,
    expectedQty: true,
    lineAmount: false,
    locationCode: false,
    no: true,
    poNo: true,
    purchasedPrice: false,
    qtyToRequisition: true,
    refInfo: true,
    requirementDetails: false,
    requisitionedQty: true,
    unitOfMeasure: false,
    taskNo: false,
    type: true,
    variantCode: false,
    vendorName: true,
    vendorNo: false,
  };
}

export interface ProcurementReturn {
  id: string;
  poNo?: string;
  error?: string;
  groupId: number;
}

export enum ProcurementGrouping {
  none = 0,
  allTogether = 1,
  byVendor = 2,
}

export enum PurchaseMode {
  configuration = 0,
  expense = 1,
  procurement = 2,
}
