import { FormRequestSettingsShort } from './form-request-settings-short';

export class FormRequestSettings {
  type = 'short';
  short = new FormRequestSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}
