/* eslint-disable @typescript-eslint/naming-convention */
export class FormRequirementsModalSettings {
  actual_Quantity = true;
  bin_Code = false;
  charge_Type = true;
  description = true;
  description_2 = true;
  details = false;
  enabled = true;
  entry_Type = false;
  expected_Quantity = false;
  location = true;
  posted_Quantity = true;
  requisitioned_Qty = false;
  unit = true;
  unit_Cost = false;
  variant_Code = false;
  vendor = false;
  wo_Reference_Info = false;

  constructor(props?: { useExpectedQuantity: boolean }) {
    if (props?.useExpectedQuantity) {
      this.actual_Quantity = false;
      this.expected_Quantity = true;
    }
  }
}
