/* eslint-disable @typescript-eslint/naming-convention */

import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
export class FormReportMaintenanceSettingsShort {
  basic_information = {
    description: true,
    duration: true,
    enabled: true,
    failure_Code: true,
    feedback_Type: true,
    status: true,
    technician_Code: true,
  };
  usage_of_this_task = {
    enabled: true,
  };
  requirements = new FormRequirementsModalSettings();
  additional_Information = {
    enabled: true,
  };
  meter_Reading = {
    enabled: true,
    meter_Value: true,
    unit_of_measure: true,
    description: true,
  };
  attachment = {
    enabled: true,
  };
}
