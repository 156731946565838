import { Injectable } from '@angular/core';
import type { GraphQLParams, SecondaryCode } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  secondaryCodes: { items: SecondaryCode[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class SecondaryCodesGQL extends Query<
  Response,
  GraphQLParams<'secondaryCode'>
> {
  document = gql(config.secondaryCode.query);
}
