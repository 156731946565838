import { Injectable } from '@angular/core';
import type { Criticality, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';
import { config } from '@tag/graphql';

export interface Response {
  criticalitys: { items: Criticality[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class CriticalitiesGQL extends Query<Response, GraphQLParams<'criticality'>> {
  document = gql(config.criticality.query);
}
