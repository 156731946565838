/* eslint-disable @typescript-eslint/naming-convention */

import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
export class FormRequestSettingsShort {
  basic_information = {
    additional_Description: false,
    facility: false,
    maint_Type: false,
    priority: false,
    priority_Rank: false,
    problem_Code: true,
    requester: true,
    status: false,
    technician_Code: false,
    work_Code: false,
    work_order_type: false,
    criticality: false,

    enabled: true,
    protected: true,
  };
  suggested_Tasks = {
    description: true,
    duration: false,
    enabled: true,
    equipment_ID: false,
    line: true,
    technician_Code: false,
    usage: true,
    work_Procedure: true,
  };
  requirements = new FormRequirementsModalSettings({ useExpectedQuantity: true });
  additional_Information = {
    enabled: false,
  };
  attachment = {
    enabled: false,
  };
}
