import { FormReportMaintenanceSettingsShort } from './form-report-maintenance-settings-short';

export class FormReportMaintenanceSettings {
  type = 'short' as const;
  short = new FormReportMaintenanceSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}
