import { FormWorkOrderSettingsShort } from './form-work-order-settings-short';

export class FormWorkOrderSettings {
  type = 'short';
  short = new FormWorkOrderSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}
