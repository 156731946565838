/* eslint-disable @typescript-eslint/naming-convention */
// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.

import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// To keep consistency with the other models. This one has the short / long type as well but is not used.
export class FormReviewRequestSettings {
  type = 'short' as const;
  short = new FormReviewRequestSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}

export class FormReviewRequestSettingsShort {
  basic_information = {
    copy_Additional_Description_To: true,
    enabled: true,
    oSP: true,
    personnel_Group: true,
    review_Note: true,
    status: true,
    supervisor_Code: true,
    technician_Code: true,
    work_Order_From_Template: true,
    work_Procedure: true,
  };
  suggested_Tasks = {
    description: true,
    duration: true,
    enabled: true,
    equipment_ID: false,
    line: true,
    technician_Code: true,
    usage: true,
    work_Procedure: true,
  };
  requirements = new FormRequirementsModalSettings();
  additional_Information = {
    enabled: false,
  };
  attachment = {
    enabled: false,
  };
}
