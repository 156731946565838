/* eslint-disable @typescript-eslint/naming-convention */
// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
// To keep consistency with the other models. This one has the short / long type as well but is not used.
export class FormReviewWorkOrderSettings {
  type = 'short' as const;
  short = new FormReviewWorkOrderSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}

export class FormReviewWorkOrderSettingsShort {
  basic_information = {
    comment: true,
    common_Section: true,
    enabled: true,
    status: true,
    technician_Code: true,
  };
  common_section = {
    enabled: true,
  };
}
