/* eslint-disable @typescript-eslint/naming-convention */

export enum ProcurementState {
  on = 'on',
  off = 'off',
}
export class ProcurementGroup {
  id = 1;
  isPurchaseOrder?: boolean;
  isSwitchDisabled?: boolean;
  isGroupDisabled?: boolean;
  isCustomVendor?: boolean;
  errorMessage?: string;
  vendorNo?: string;
  vendorName?: string;
  lines: ProcurementLine[] = [];

  constructor(
    id: number,
    vendorNo: string | undefined,
    vendorName: string | undefined,
    isPurchaseOrder: boolean,
    lines: ProcurementLine[]
  ) {
    this.id = id;
    this.vendorNo = vendorNo;
    this.vendorName = vendorName;
    this.isPurchaseOrder = isPurchaseOrder;
    this.lines = lines;
  }

  adjustGroup(): void {
    if (!this.isCustomVendor && this.lines.length !== 0) {
      const vendorNo = this.lines[0].vendorNoDefault;
      const vendorName = this.lines[0].vendorNameDefault;
      if (!vendorNo || vendorNo === '' || this.lines.find((l) => l.vendorNoDefault !== vendorNo)) {
        this.isPurchaseOrder = false;
        this.vendorNo = undefined;
        this.vendorName = undefined;
      } else {
        this.vendorNo = vendorNo;
        this.vendorName = vendorName;
      }
    }
  }
}

export const FilteredRequirements = ['Resource', 'Other', 'Tool'];

export interface ProcurementLine {
  lineNo: number;
  type?: string;
  no?: string;
  description?: string;
  locationCode?: string;
  unitOfMeasure?: string;
  unitPrice?: number;
  lineAmount?: number;
  qtyToRequisition?: number;
  purchasedPrice?: number;
  id?: string;
  binCode?: string;
  refInfo?: string;
  groupNo?: number;
  sourceNo?: string;
  sourceLineNo?: number;
  poNo?: string;
  projAvailBalance?: string;
  withRequisition?: boolean;
  expectedQty?: number;
  requirementDetails?: string;
  requisitionedQty?: number;
  tagWoSourceNo?: string;
  variantCode?: string;
  vendorNo?: string;
  vendorName?: string;
  vendorNameDefault?: string;
  vendorNoDefault?: string;
}
