import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, NoSerie } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  noSeries: {
    items: NoSerie[];
    totalCount: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class NoSeriesGQL extends Query<Response, GraphQLParams<'noSerie'>> {
  document = gql(config.noSerie.query);
}
