/* eslint-disable @typescript-eslint/naming-convention */

import { FormRequirementsModalSettings } from './form-requirements-modal-settings';

// Variable name is not camelCase, but the underscore is used to properly parse the property name in a readable format in HTML.
export class FormCompleteWorkOrderSettingsShort {
  report_Work_Order_Maintenance = {
    enabled: true,
    feedback_Type: true,
    work_Order_Report: true,
    protected: true,
  };
  select_Lines = {
    description: true,
    enabled: true,
    finished: true,
    line: true,
    technician_Code: true,
    protected: false,
  };
  time_Entry = {
    description: true,
    duration: true,
    enabled: true,
    equipment_ID: false,
    finished: true,
    line: true,
    starting_Datetime: false,
    work_Procedure: true,
  };
  test_Result = {
    enabled: true,
  };
  requirements = new FormRequirementsModalSettings();
  note = {
    enabled: true,
  };
  attachment = {
    enabled: true,
  };
}
