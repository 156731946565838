import { FormInspectionSettingsShort } from './form-inspection-settings-short';

export class FormInspectionSettings {
  type = 'short' as const;
  short = new FormInspectionSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}
