import { FormWorkOrderLineSettingsShort } from './form-work-order-line-settings-short';

export class FormWorkOrderLineSettings {
  type = 'short';
  short = new FormWorkOrderLineSettingsShort();
  singleStyle = true;
  constructor(type: 'short') {
    this.type = type;
  }
}
